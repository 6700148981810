import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button, FormControl, InputGroup } from "react-bootstrap";
import styled from "styled-components";
import Loader from "react-loader-spinner";
import Swal from "sweetalert2";
import IdCard from "../components/IdCard";
import { GetCall, PostCall, GetImage, PostCallResponse } from "../services/KycService";
import ImageModal from "./ImageModal";
import SelfieModal from "./SelfieModal";
import FrontModal from "./FrontModal";
import BackModal from "./BackModal";
import { data } from "jquery";
import { KYC_BUCKET_NAME, BASE_URL } from '../config';
import { Auth } from "aws-amplify";
import { getS3PresignedData, uploadFileToS3 } from "../services/s3one";
import axios from "axios";
import moment from "moment";
const KycDetails = (props) => {
  const [uid, setUid] = useState("");
  useEffect(() => {
    if (props.history) {
      setUid(props.history.location.state.uid);
    } else {
      setUid(props.uid);
    }
  });
  // const uid = props.history.location.state.uid;
  const [user, setUser] = useState([]);
  const [kycStat, setStat] = useState("nil");
  const [accBtn, setAccBtn] = useState(false);
  const [rejBtn, setRejBtn] = useState(false);
  const [frontImg, setFrontImg] = useState("");
  const [backImg, setBackImg] = useState("");
  const [img, setImg] = useState("");
  const [selfieImg, setSelfieImg] = useState("");
  const [dob, setDob] = useState("");
  const [timestamp, setTimestamp] = useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow2, setModalShow2] = React.useState(false);
  const [modalShow3, setModalShow3] = React.useState(false);
  const [modalShow4, setModalShow4] = React.useState(false);
  const [helperText, setHelperText] = useState("d-none")

  // const [updateIcUrl, setUpdatedIcUrl] = useState (null);
  const [updateDiUrl, setUpdatedDiUrl] = useState(null);
  const [updateSiUrl, setUpdatedSiUrl] = useState(null);
  const [updateDFUrl, setUpdatedDFUrl] = useState(null);
  const [updateDBUrl, setUpdatedDBUrl] = useState(null);

  const [editingEmail, setEditingEmail] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [emailUpdate, setEmailUpdate] = useState(false);


  // console.log(updateDFUrl)
  // console.log("user", user);
  useEffect(() => {
    const fetchIndividualKyc = async () => {
      const res = await GetCall("getUserKycDetails/?uid=" + uid);
      // console.log("DOB = ",res.message.dob)
      // console.log(res.message)
      const dateString = res.message.dob;
      const formatDate = (dateString) => {
        const options = { year: "numeric", month: "numeric", day: "numeric" };
        return new Date(dateString).toLocaleDateString(undefined, options);
      };
      const dob = formatDate(dateString);
      setDob(dob);

      const timest = res.message.timestamp;
      let timesty;
      if (timest !== undefined) {
        timesty = timest.replace("T", " ")
        timesty = new Date(`${timesty} UTC`)
      }
      const formattimest = (timest) => {
        const options = {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        };
        return new Date(timest).toLocaleDateString(undefined, options);
      };
      const timestamp = formattimest(timesty);
      setTimestamp(timestamp);
      if (res.message) {
        setUser(res.message);
        if (res.message.kyc_status === "A") {
          setStat("Accepted");
          setAccBtn(true);
        }
        if (res.message.kyc_status === "D") {
          setStat("Rejected");
          setRejBtn(true);
          setHelperText("");
        }
        if (res.message.kyc_status === "S") {
          setStat("Submitted");
          setAccBtn(false);
          setRejBtn(false);
          setHelperText("");
        }
        if (!res.message.hasOwnProperty("kyc_status")) {
          setStat("error");
          setAccBtn(true);
          setRejBtn(true);
        }
      } else {
        throw new Error("API is not returning the data");
      }
    };
    fetchIndividualKyc();
  }, [uid, kycStat, emailUpdate]);

  useEffect(() => {
    const fetchImageLink = async () => {
      const img_url = user.document_image_url;
      //  let key;
      try {
        const pathArray = img_url.split('/');
        const path_image = pathArray[pathArray.length - 2] + '/' + pathArray[pathArray.length - 1]
        const profile_image = await GetImage(KYC_BUCKET_NAME, path_image);
        setImg(profile_image.message);
      } catch (err) {
        console.log(err);
      }
    };
    fetchImageLink();

    const fetchFrontImageLink = async () => {
      const img_url = user.id_document_front;
      //  let key;
      try {
        const pathArray = img_url.split('/');
        const path_image = pathArray[pathArray.length - 2] + '/' + pathArray[pathArray.length - 1]
        const profile_image = await GetImage(KYC_BUCKET_NAME, path_image);
        setFrontImg(profile_image.message);
      } catch (err) {
        console.log(err);
      }
    };
    fetchFrontImageLink();

    const fetchBackImageLink = async () => {
      const img_url = user.id_document_back;
      //  let key;
      try {
        const pathArray = img_url.split('/');
        const path_image = pathArray[pathArray.length - 2] + '/' + pathArray[pathArray.length - 1]
        const profile_image = await GetImage(KYC_BUCKET_NAME, path_image);
        setBackImg(profile_image.message);
      } catch (err) {
        console.log(err);
      }
    };
    fetchBackImageLink();


    const fetchSelfieLink = async () => {
      const img_url = user.selfie_image_url;
      //  let key;
      try {
        const pathArray = img_url.split('/');
        const path_image = pathArray[pathArray.length - 2] + '/' + pathArray[pathArray.length - 1]
        const profile_image = await GetImage(KYC_BUCKET_NAME, path_image);
        setSelfieImg(profile_image.message);
      } catch (err) {
        console.log(err);
      }
    };

    fetchSelfieLink();
    if (user.kyc_status === "A") {
      setRejBtn(false);
      setAccBtn(true);
    } else if (user.kyc_status === "D") {
      setRejBtn(true);
      setAccBtn(false);
    } else {
      setRejBtn(false);
      setAccBtn(false);
    }
  }, [user]);

  const handleEmailUpdate = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 
  
    if (!emailRegex.test(newEmail)) {
      Swal.fire("Error", "Invalid email format! Please enter a valid email.", "error");
      return; 
    }
  
    try {
      const res = await PostCallResponse("updateUserEmail", {
        uid: user.uid,
        email: newEmail,
      });
  
      if (res.status === 200) {  
        setEditingEmail(false);
        setEmailUpdate(true);
        setNewEmail("");
        Swal.fire("Success!", res.data.message || "Email updated successfully.", "success");
      } else {
        setNewEmail("");
        
        throw new Error(res.response.data.message|| "API call failed");
      }
    } catch (error) {
      console.log(error.message)
      Swal.fire("Error", error.message || "Failed to update email. Please try again.", "error");
      console.error("Email update error:", error);
    }
  };
  
  

  const onStatChange = async (input_data) => {
    // if(res){
    let changeData = input_data === "A" ? "Accepted" : "Rejected";
    console.log(changeData);
    if (input_data === "A") {
      const info = { uid: user.uid, kyc_status: input_data };
      const res = await PostCall("updateUserKyc/", info);
      console.log(res);
      setAccBtn(true);
      setRejBtn(false);
      setStat("Accepted");
      Swal.fire("Success!", "This KYC is approved.", "success");
    } else {
      const { value: text } = await Swal.fire({
        input: "textarea",
        inputLabel: "Rejection Note",
        inputPlaceholder: "Type Rejection Note...",
        inputAttributes: {
          "aria-label": "Type Rejection Note",
        },
        showCancelButton: true,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value.length > 0) {
              resolve()
            } else {
              resolve('You need to enter the rejection note.')
            }
          })
        }
      });
      if (text) {
        const info = {
          uid: user.uid,
          kyc_status: input_data,
          rejection_note: text,
        };
        const res = await PostCall("updateUserKyc/", info);
        console.log(res);
        setStat("Rejected");
        setRejBtn(true);
        Swal.fire("Success!", "This KYC is rejected. Please resubmit the KYC!", "success");
      }
    }
    // }
  };

  const updateCovidStatus = async (type) => {
    let body = {
      name: user.givenName + " " + user.familyName,
      covid_allowance_level: type,
    }
    if (user.hasOwnProperty('email')) {
      body = { ...body, email: user.email };
    }
    if (user.hasOwnProperty('phone_number')) {
      body = { ...body, phone_number: user.phone_number };
    }
    try {
      const res = await PostCall('saveCovidVC', body);
      console.log(res);
      if (!res.ok) {
        Swal.fire("Something went wrong. Certificate cannot be created")
      } else {
        let msg = type === 1 ? "Vaccine Exemption issued successfully" : "Mask Exemption issued successfully";
        Swal.fire(msg);
        window.location.reload();
      }
    } catch (e) {
      console.log(e)
    }
  }

  const implementGet = async () => {
    let res = await GetCall(`getCovidVC?sponsor=yidindji&certificate=${user.certificate_number}`)
      .then((res) => console.log(res));
    return res;
  }

  function getCovidCertificate() {

    if (user.hasOwnProperty('certificate_number')) {

      let res = implementGet();

      if (res.message.certificate_issued) {
        if (res.message.covid_allowance_level === "1") {
          return (
            <div className="row">
              <div className="col">
                User is exempted from vaccination.
              </div>
            </div>
          );
        } else if (res.message.covid_allowance_level === "2") {
          return (
            <div className="row">
              <div className="col">
                User is exempted from wearing mask.
              </div>
            </div>
          )
        }
      }

    } else {
      if (user.hasOwnProperty('kyc_status')) {
        if (user.kyc_status === 'A') {
          return (
            <div className="row justify-content-center mt-3">
              <div className="col-3">
                <button
                  className="btn btn-warning"
                  onClick={() => updateCovidStatus("2")}
                >
                  Issue Mask Exemption
                </button>
              </div>
              <div className="col-3">
                <button
                  className="btn btn-info"
                  onClick={() => updateCovidStatus("1")}
                >
                  Issue Vaccine Exemption
                </button>
              </div>
            </div>
          );
        } else {
          return (
            <div className="row">
              <div className="col">
                Please wait for your kyc to be approved
              </div>
            </div>
          )
        }
      }
    }
  };
  // const updateIdImage = async (selectedFileObj, docType) => {
  //   const { name, type } = selectedFileObj;
  //   let idToken;
  //   await Auth.currentSession().then((data) => {
  //     console.log("JWTTOKEN", data.idToken.jwtToken);
  //     idToken = data.idToken.jwtToken;
  //   });
  //   const preSignedObj = await getS3PresignedData(
  //     idToken,
  //     name,
  //     "ssid-user-kyc-documents",
  //     false,
  //     type,
  //     user.email,
  //     user.phone_number,
  //   );
  //   uploadFileToS3(preSignedObj.data, selectedFileObj)
  //     .then((res) => {
  //       let fileUrl = `${preSignedObj.data.url}${preSignedObj.data?.fields?.key}`;
  //       setUpdatedIcUrl(fileUrl);
  //       // docType === "document" ? setselfieUrl(fileUrl) : setFileUrl(fileUrl);
  //     })
  //     .catch((_err) => alert("Upload Failed, Please try again..", _err));
  // };
  const updateSelfImage = async (selectedFileObj, docType) => {
    const { name, type } = selectedFileObj;
    let idToken;
    await Auth.currentSession().then((data) => {
      console.log("JWTTOKEN", data.idToken.jwtToken);
      idToken = data.idToken.jwtToken;
    });
    const preSignedObj = await getS3PresignedData(
      idToken,
      name,
      "ssid-user-kyc-documents",
      false,
      type,
      user.email,
      user.phone_number,
    );
    uploadFileToS3(preSignedObj.data, selectedFileObj)
      .then((res) => {
        let fileUrl = `${preSignedObj.data.url}${preSignedObj.data?.fields?.key}`;
        setUpdatedSiUrl(fileUrl);
        // docType === "document" ? setselfieUrl(fileUrl) : setFileUrl(fileUrl);
      })
      .catch((_err) => alert("Upload Failed, Please try again..", _err));
  };

  const updateDocImage = async (selectedFileObj, docType) => {
    const { name, type } = selectedFileObj;
    let idToken;
    await Auth.currentSession().then((data) => {
      console.log("JWTTOKEN", data.idToken.jwtToken);
      idToken = data.idToken.jwtToken;
    });
    const preSignedObj = await getS3PresignedData(
      idToken,
      name,
      "ssid-user-kyc-documents",
      false,
      type,
      user.email,
      user.phone_number,
    );
    uploadFileToS3(preSignedObj.data, selectedFileObj)
      .then((res) => {
        let fileUrl = `${preSignedObj.data.url}${preSignedObj.data?.fields?.key}`;
        setUpdatedDiUrl(fileUrl);
        // docType === "document" ? setselfieUrl(fileUrl) : setFileUrl(fileUrl);
      })
      .catch((_err) => alert("Upload Failed, Please try again..", _err));
  };
  const updateFrontImage = async (selectedFileObj, docType) => {
    const { name, type } = selectedFileObj;
    let idToken;
    await Auth.currentSession().then((data) => {
      console.log("JWTTOKEN", data.idToken.jwtToken);
      idToken = data.idToken.jwtToken;
    });
    const preSignedObj = await getS3PresignedData(
      idToken,
      name,
      "ssid-user-kyc-documents",
      false,
      type,
      user.email,
      user.phone_number,
    );
    uploadFileToS3(preSignedObj.data, selectedFileObj)
      .then((res) => {
        let fileUrl = `${preSignedObj.data.url}${preSignedObj.data?.fields?.key}`;
        setUpdatedDFUrl(fileUrl);
        // docType === "document" ? setselfieUrl(fileUrl) : setFileUrl(fileUrl);
      })
      .catch((_err) => alert("Upload Failed, Please try again..", _err));
  };
  const updateBackImage = async (selectedFileObj, docType) => {
    const { name, type } = selectedFileObj;
    let idToken;
    await Auth.currentSession().then((data) => {
      console.log("JWTTOKEN", data.idToken.jwtToken);
      idToken = data.idToken.jwtToken;
    });
    const preSignedObj = await getS3PresignedData(
      idToken,
      name,
      "ssid-user-kyc-documents",
      false,
      type,
      user.email,
      user.phone_number,
    );
    uploadFileToS3(preSignedObj.data, selectedFileObj)
      .then((res) => {
        let fileUrl = `${preSignedObj.data.url}${preSignedObj.data?.fields?.key}`;
        setUpdatedDBUrl(fileUrl);
        // docType === "document" ? setselfieUrl(fileUrl) : setFileUrl(fileUrl);
      })
      .catch((_err) => alert("Upload Failed, Please try again..", _err));
  };
  // console.log(user)
  const imageUpdateHandler = async (type) => {
    let CitizenFormData = {}
    let suid, semail

    await Auth.currentSession().then((data) => {
      suid = user.uid;
      semail = user.email;

    });

    CitizenFormData = {
      kyc_status: user.kyc_status,
      uid: suid,
    }
    if (type === "docimg") {
      CitizenFormData = updateDiUrl !== null ? { ...CitizenFormData, document_image_url: updateDiUrl } : CitizenFormData
    } else if (type === "selfiimg") {
      CitizenFormData = updateSiUrl !== null ? { ...CitizenFormData, selfie_image_url: updateSiUrl } : CitizenFormData
    }
    else if (type === "frontselfiimg") {
      CitizenFormData = updateDFUrl !== null ? { ...CitizenFormData, id_document_front: updateDFUrl } : CitizenFormData
    } else if (type === "backselfiimg"); {
      CitizenFormData = updateDBUrl !== null ? { ...CitizenFormData, id_document_back: updateDBUrl } : CitizenFormData
    }

    console.log(CitizenFormData, "citizen data");
    return Auth.currentSession().then((data) => {
      //console.log("JWTTOKEN", data); 
      axios({
        method: "post",
        url: `${BASE_URL}updateUserKyc`,
        data: CitizenFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: data.idToken.jwtToken,
        },
      })
        .then((res) => {
          Swal.fire("Success!", "File Uploaded successfully", "success");
        })
        .catch((err) => console.log("Error During Upload File...", err));
    });

  }
  return (
    <>
      <Body>
        <Container>
          <Wrapper>
            <div style={action_btn}>
              {kycStat === "nil" ? (
                <Loader type="ThreeDots" color="black" />
              ) : (
                <div class="ui buttons">
                  <button
                    disabled={accBtn}
                    class="ui positive button"
                    onClick={() => onStatChange("A")}
                  >
                    Accept KYC
                  </button>
                  <div class="or"></div>
                  <button
                    disabled={rejBtn}
                    class="ui negative button"
                    onClick={() => onStatChange("D")}
                  >
                    Reject KYC
                  </button>
                </div>
              )}
            </div>
            <div className={`text-center mt-2 ${helperText}`}>
              <h6>Please make sure you have thoroughly verified User's data before approving the KYC.</h6>
            </div>
            <br />
            <br />
            <div className="setUserStamp">
              <div className="row justify-content-center">
                {/* ADD approved by or rejected by user in front of name in the getName function */}
                {user &&
                  user.kyc_status === "A" &&
                  user.hasOwnProperty("approvedBy") &&
                  user.approvedBy && (
                    <table className="table table-bordered">
                      <thead>
                        <tr style={{ padding: "20px" }} >
                          <th scope="col-4" >
                            <h5 className="text-center">
                              <span style={{ color: "green" }}>
                                KYC Accepted by: &nbsp;
                              </span>
                              {user?.approvedBy.firstName +
                                " " +
                                user?.approvedBy.lastName}
                            </h5>
                          </th>
                          <th scope="col-4">
                            <h5 className="text-center">
                              At: &nbsp; {moment.utc(user?.approvedBy.updatedAt).local().format("LLL")}
                            </h5>
                          </th>
                        </tr>
                      </thead>
                    </table>
                  )}
                {user &&
                  user.kyc_status === "D" &&
                  user.hasOwnProperty("rejectedBy") &&
                  user.rejectedBy && (
                    <table className="table table-bordered" >
                      <thead>
                        <tr style={{ padding: "20px" }} >
                          <th scope="col-4" >
                            <h5 className="text-center">
                              <span style={{ color: "red" }}>
                                KYC Rejected by: &nbsp;
                              </span>
                              {user?.rejectedBy.firstName +
                                " " +
                                user?.rejectedBy.lastName}
                            </h5>
                          </th>
                          <th scope="col-4">
                            <h5 className="text-center">
                              At: &nbsp; {moment.utc(user?.rejectedBy.updatedAt).local().format("LLL")}
                            </h5>
                          </th>
                        </tr>
                      </thead>
                    </table>
                  )}
              </div>
            </div>
            <br />
            <IdCard data={user} img={selfieImg} dob={dob} />
            <br />
            <br />
            <br />
            <Divider />
            <Row>
              <Col>
                <Card style={card_style}>
                  <Card.Body>
                    <Card.Title style={card_title}>Basic Details</Card.Title>

                    <Card.Text>
                      <p>
                        {user.email ? "Email : " : "Phone Number : "}
                        {editingEmail ? (
                          <InputGroup>
                            <FormControl
                              type="email"
                              value={newEmail}
                              onChange={(e) => setNewEmail(e.target.value)}
                              onKeyDown={(e) => e.key === "Enter" && handleEmailUpdate()}
                            />
                            <button variant="success" onClick={handleEmailUpdate}>
                              OK
                            </button>
                          </InputGroup>
                        ) : (
                          <>
                            <b>{user.email ? user.email : user.phone_number}</b> &nbsp;
                            {user.email && (
                              <button variant="link" onClick={() => setEditingEmail(true)}>
                                Edit
                              </button>
                            )}
                          </>
                        )}
                      </p>
                      <p>
                        Given Name :{" "}
                        <b>
                          {user.givenName ? user.givenName : user.first_name}
                        </b>
                      </p>
                      <p>
                        Family Name :{" "}
                        <b>
                          {user.familyName ? user.familyName : user.last_name}
                        </b>
                      </p>
                      <p>
                        DOB : <b>{user.dob}</b>
                      </p>
                    </Card.Text>

                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card style={card_style}>
                  <Card.Body>
                    <Card.Title style={card_title}>KYC Details</Card.Title>

                    <Card.Text>
                      <p>
                        KYC Status : <b>{kycStat}</b>
                      </p>
                      <p>
                        KYC Submission Date : <b>{timestamp}</b>
                      </p>
                      <br />
                      {user.kyc_status === "D" || kycStat === "Rejected" ? (
                        <p style={{ color: "red" }}>
                          Rejection Note : <b>{user.rejection_note}</b>
                        </p>
                      ) : null}
                      {/* <p style={{color: 'red'}}>Rejection Note : <b>{user.rejection_note}</b></p>  */}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col>
                <Card style={card_style}>
                  <Card.Body>
                    <Card.Title style={card_title}>Address</Card.Title>

                    <Card.Text>
                      <p>
                        {user.housingNo ? "Housing Number :" : null}{" "}
                        <b>{user.housingNo ? user.housingNo : ""}</b>
                      </p>
                      <p>
                        Residential Address :{" "}
                        <b>
                          {user.residentalAddr
                            ? user.residentalAddr
                            : user.address}
                        </b>
                      </p>
                      <p>
                        Postal Address :{" "}
                        <b>
                          {user.postalAddr ? user.postalAddr : user.address}
                        </b>
                      </p>
                      {/* <p>ZIP Code : {user.zip_code}</p>  */}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              {user.office ? (
                <>
                  <Col>
                    <Card style={card_style}>
                      <Card.Body>
                        <Card.Title style={card_title}>
                          Document Details of Govt. Worker
                        </Card.Title>

                        <Card.Text>
                          <p>
                            Date of official apointment :{" "}
                            <b> {user.dateOfAppointment} </b>
                          </p>
                        </Card.Text>
                        <Card.Text>
                          <p>
                            Document Name:
                            <b> {user.id_document_name} </b>
                          </p>
                        </Card.Text>
                        <Card.Text>
                          <p>
                            Document Number :
                            <b> {user.id_document_number} </b>
                          </p>
                        </Card.Text>
                        <Card.Text>
                          <p>
                            Document Expiry Date :
                            <b> {user.id_document_expiry} </b>
                          </p>
                        </Card.Text>
                        <Card.Text>
                          <p>
                            Citizenship ID Number :
                            <b> {user.citizenshipIDNo} </b>
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </>
              ) : (
                <>
                  <Col>
                    <Card style={card_style}>
                      <Card.Body>
                        <Card.Title style={card_title}>
                          Document Details
                        </Card.Title>

                        <Card.Text>

                          <p>
                            Passport Number : <b>{user.passportNumber}</b>
                          </p>
                        </Card.Text>
                        <Card.Text>
                          <p>
                            Document Name:
                            <b> {user.id_document_name} </b>
                          </p>
                        </Card.Text>
                        <Card.Text>
                          <p>
                            Document Number :
                            <b> {user.id_document_number} </b>
                          </p>
                        </Card.Text>
                        <Card.Text>
                          <p>
                            Document Expiry Date :
                            <b> {user.id_document_expiry} </b>
                          </p>
                        </Card.Text>
                        <Card.Text>
                          <p>
                            Citizenship ID Number :
                            <b> {user.citizenshipIDNo} </b>
                          </p>
                        </Card.Text>
                        <br />
                        {/* <p>UID : {user.uid}</p>  */}

                      </Card.Body>
                    </Card>
                  </Col>
                </>
              )}
            </Row>
            <Divider />
            {user.office ? (
              <>
                <Row>
                  <Col>
                    <Card style={card_style}>
                      <Card.Body>
                        <Card.Title style={card_title}>
                          Government Office
                        </Card.Title>

                        <Card.Text>
                          <p>
                            Office or Department : <b>{user.office}</b>
                          </p>
                          <p>
                            Office Level : <b>{user.officeLevel}</b>
                          </p>
                          <p>
                            Security Clearence Level :{" "}
                            <b>{user.securityClearanceLevel}</b>
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card style={card_style}>
                      <Card.Body>
                        <Card.Title style={card_title}>
                          {" "}
                          Other Government Details
                        </Card.Title>

                        <Card.Text>
                          <p>
                            Compensation Code : <b>{user.compensationCode}</b>
                          </p>
                          <p>
                            Pro-W Level : <b>{user.proWLevel}</b>
                          </p>
                          <p>
                            Pro-W Authorization No : <b> {user.proWAuthNo} </b>
                          </p>
                          <br />
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col>
                    <Card style={card_style}>
                      <Card.Body>
                        <Card.Title style={card_title}>
                          Vehicle Class License
                        </Card.Title>

                        <Card.Text>
                          <p>
                            Vehicle Class License : <b>{user.vehicalClass}</b>
                          </p>
                          <p>
                            Vehicle License Number :
                            <b>{user.vehicalLicenseNo}</b>
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card style={card_style}>
                      <Card.Body>
                        <Card.Title style={card_title}>
                          {" "}
                          Other Details
                        </Card.Title>

                        <Card.Text>
                          <p>
                            Doc Type : <b>Health Card</b>
                          </p>
                          <p>
                            Health Card Number : <b>{user.healthCardNo}</b>
                          </p>
                          <br />
                          {/* <p>UID : {user.uid}</p>  */}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </>
            )}

            <Divider />
            {
              !user.id_document_front ? (
                null
              ) : (<>
                <Row>
                  <Col>
                    <Card style={card_style}>
                      <Card.Body>
                        <Card.Title style={card_title}>Document Front</Card.Title>

                        <Card.Text>
                          <Card style={card_style2}>
                            <p
                              style={img_user}
                              variant="primary"
                              onClick={() => setModalShow3(true)}
                            >
                              <Card.Img style={img_user} variant="top" src={frontImg} />
                            </p>
                            <FrontModal
                              img3={frontImg}
                              show={modalShow3}
                              onHide={() => setModalShow3(false)}
                            />
                            <div className="col-10 mt-1">
                              <label htmlFor="idcocImgUp" className="form-label">Update Document Front Image</label>
                              <div className="row ml-0">
                                <input accept="image/*" className="col-9 form-control" type="file" id="idcocImgUp" onChange={(e) => updateFrontImage(e.target.files[0])} />
                                <button className="btn btn-primary col-3" onClick={(e) => imageUpdateHandler("frontselfiimg")}
                                  disabled={updateDFUrl === null ? true : false}
                                >Submit</button>
                              </div>
                            </div>
                          </Card>

                        </Card.Text>

                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card style={card_style}>
                      <Card.Body>
                        <Card.Title style={card_title}>Document Back</Card.Title>

                        <Card.Text>
                          <Card style={card_style2}>
                            <p
                              style={img_user}
                              variant="primary"
                              onClick={() => setModalShow4(true)}
                            >
                              <Card.Img
                                style={img_user}
                                variant="top"
                                src={backImg}
                              />
                            </p>
                            <BackModal
                              img4={backImg}
                              show={modalShow4}
                              onHide={() => setModalShow4(false)}
                            />
                            <div className="col-10 mt-1">
                              <label htmlFor="idbackImgUp" className="form-label">Update Document Back Image</label>
                              <div className="row ml-0">
                                <input accept="image/*" className="col-9 form-control" type="file" id="idbackImgUp" onChange={(e) => updateBackImage(e.target.files[0])} />
                                <button className="btn btn-primary col-3" onClick={(e) => imageUpdateHandler("backselfiimg")}
                                  disabled={updateDBUrl === null ? true : false}
                                >Submit</button>
                              </div>
                            </div>
                          </Card>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

              </>)

            }
            <Row>
              <Col>
                <Card style={card_style}>
                  <Card.Body>
                    <Card.Title style={card_title}>Document Details</Card.Title>

                    <Card.Text>
                      <Card style={card_style2}>
                        <p
                          style={img_user}
                          variant="primary"
                          onClick={() => setModalShow2(true)}
                        >
                          <Card.Img style={img_user} variant="top" src={img} />
                        </p>
                        <ImageModal
                          img={img}
                          show={modalShow2}
                          onHide={() => setModalShow2(false)}
                        />
                        <div className="col-10 mt-1">
                          <label htmlFor="idCImgUp" className="form-label">Update Document Image</label>
                          <div className="row ml-0">
                            <input accept="image/*" className="col-9 form-control" type="file" id="idCImgUp" onChange={(e) => updateDocImage(e.target.files[0])} />
                            <button className="btn btn-primary col-3" onClick={(e) => imageUpdateHandler("docimg")}
                              disabled={updateDiUrl === null ? true : false}
                            >Submit</button>
                          </div>
                        </div>
                      </Card>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card style={card_style}>
                  <Card.Body>
                    <Card.Title style={card_title}>Selfie</Card.Title>

                    <Card.Text>
                      <Card style={card_style2}>
                        <p
                          style={img_user}
                          variant="primary"
                          onClick={() => setModalShow(true)}
                        >
                          <Card.Img
                            style={img_user}
                            variant="top"
                            src={selfieImg}
                          />
                        </p>
                        <SelfieModal
                          img2={selfieImg}
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <div className="col-10 mt-1">
                          <label htmlFor="idselImgUp" className="form-label">Update Selfie Image </label>
                          <div className="row ml-0">
                            <input accept="image/*" className="col-9 form-control" type="file" id="idselImgUp" onChange={(e) => updateSelfImage(e.target.files[0])} />
                            <button className="btn btn-primary col-3" onClick={(e) => imageUpdateHandler("selfiimg")}
                              disabled={updateSiUrl === null ? true : false}
                            >Submit</button>
                          </div>
                        </div>
                      </Card>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Divider />
            {/* <h5>Covid Status</h5>
            {getCovidCertificate()} */}
          </Wrapper>
        </Container>
      </Body>
    </>

  );
};
export default KycDetails;

const card_style = {
  position: "relative",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
};
const card_style2 = {
  width: "100%",
  height: "100%",
};
const card_title = {
  background: "#a7a7a7",
  textAlign: "center",
  color: "black",
};
const img_user = {
  height: "250px",
  width: "100%",
  cursor: "pointer",
};
const action_btn = {
  position: "relative",
  left: "50%",
  transform: "translate(-15%)",
};

const Body = styled.div`
  margin-top: 100px;
  width: 100%;
  height: 100%;
  padding: 40px;
  margin-bottom: 50px;
  border: 1px solid grey;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;
const Wrapper = styled.div`
  width: 100%;
`;

const Divider = styled.div`
  width: 100%;
  height: 20px;
  background-color: #7e5737;
  margin-top: 10px;
  margin-bottom: 10px;
`;
